import React from 'react';
import html2canvas from 'html2canvas';

// CSS
import './css/tweet.scss';

// Images
import wotsit from './imgs/smoulderingwotsit.jpg';
import reply from './imgs/reply.png';
import like from './imgs/like.png';
import retweet from './imgs/retweet.png';
import share from './imgs/share.png';
import verified from './imgs/verified.png';
import arrow from './imgs/arrow.png';


class Tweet extends React.Component {

  generateRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }


  createStats = () => {
    const sections = [
      {
        name: `reply`,
        icon: reply,
      },
      {
        name: `retweet`,
        icon: retweet,
      },
      {
        name: `like`,
        icon: like,
      },
      {
        name: `share`,
        icon: share,
      },
    ]

    let stats = [];
    for(const stat of sections) {
      stats.push(<span className="tweet__stat" key={stat.name}><img style={{ width: '1.25em', height: '1.25em' }} src={stat.icon} alt={stat.name}/> {stat.name !== "share" ? this.generateRandomInt(150) + `k` : ``}</span>)
    }
    return stats;
  }

  saveTweetAsImage = () => {
    let imageData;
    html2canvas(document.getElementById("tweet")).then(canvas => {
      imageData = canvas.toDataURL('image/jpg', 0.5);
      // console.log(imageData);;
    })

    const downloadButton = document.createElement("a"); //Create <a>
    downloadButton.href = "data:image/jpg;base64," + imageData; //Image Base64 Goes here
    downloadButton.download = "meanTweet.jpg"; //File name Here
    downloadButton.click(); //Downloaded file
  }

  tweetBody = () => {
    let formattedTweet;
    const tweet = this.props.tweet;
    const atPosition = tweet.search('@');
    if(atPosition < 0) {
      formattedTweet = <p className="tweet__body">{ this.props.tweet }</p>
    }
    else {
      const beforeHandle = tweet.substring(0, atPosition);
      const afterHandle = tweet.substring(atPosition);
      const handle = afterHandle.split(' ')[0];
      const rest = afterHandle.substring(handle.length)
      formattedTweet = <p className="tweet__body">{beforeHandle} <span className="tweet__body-handle">{handle}</span> {rest}</p>
    }

    return formattedTweet;
  }

  render() {
    return (
      <div>
        {/*<button onClick={this.saveTweetAsImage}>Save Tweet</button>*/}
        <div className="tweet" id="tweet">
          <img className="tweet__dp" src={wotsit} alt=""/>
          <div className="tweet__main">
            <div className="tweet__header">
              <span className="tweet__name"><p>Donald J Trump </p> <img style={{ width: '1.25em' }} src={verified} alt=""/></span>

              <span className="tweet__handle">@realDonaldTrump</span>
              <p className="tweet__time">.&nbsp;{ this.generateRandomInt(24) }h</p>
            </div>
            { this.tweetBody() }
            {/*<p className="tweet__body">{ this.props.tweet }</p>*/}
            <div className="tweet__stats">
              { this.createStats() }
              {/*<img style={{ width: '1.25em' }} src={share} alt="share"/>*/}

            </div>
          </div>
          <div className="tweet__icons">
            <img style={{ width: '1.25em' }} src={arrow} alt="arrow"/>
          </div>
        </div>
        <p className="disclaimer"><span role="img" aria-label="pointing up emoji">☝️</span>The above is totally FAKE NEWS from the CROOKED liberal press. Screenshot and crop to save <span role="img" aria-label="pointing up emoji">☝️</span></p>
      </div>

    )
  }
}

export default Tweet;
