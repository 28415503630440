import React from 'react';
import './App.scss';



// Components
import Input from './components/Input.jsx';
import Tweet from './components/Tweet.jsx';
// Images
import star from './assets/star.svg';


class App extends React.Component {

  state = {
    tweet: ''
  }

  createStars = () => {
    let stars = [];
    for(let i = 0; i < 3; i++) {
      stars.push(<img key={i} className="star" src={star} alt=""/>)
    }

    return stars
  }

  getTweetFromInput = (tweetFromInput) => {
    this.setState({tweet: tweetFromInput});
  }

  showTweet = () => {
    if(this.state.tweet) {
      return <Tweet tweet={this.state.tweet} />
    }

    return <p className="instructions"><span role="img" aria-label="pointing up emoji">☝️</span>Enter your name to find out exactly what Trump's been tweeting about you. Screenshot to save <span role="img" aria-label="pointing up emoji">☝️</span></p>
  }


  render() {
    return (
      <div className="App">
        <div className="group">
          <h1>Get Trumped</h1>
          <div className="stars">
            { this.createStars() }
          </div>
          <div className="input">
            <Input callbackFromParent={this.getTweetFromInput} />
          </div>
          { this.showTweet() }
        </div>
        <footer className="footer">
          <p>Created by <a target="_blank" rel="noopener noreferrer" href="https://harrietmcmahon.com/">Harriet McMahon</a> using the <a target="_blank" rel="noopener noreferrer" href="https://whatdoestrumpthink.com/">What Does Trump Think API </a></p>
        </footer>
      </div>
    );
  }
}

export default App;
