import React from 'react';
import axios from 'axios';

// import Tweet from './Tweet.jsx';
import './css/input.scss';


class Input extends React.Component {

  constructor(props) {
    super(props);
    this.state = {name: ``};

    this.handleNameInput = this.handleNameInput.bind(this);
  }
  state = {
    btnActive: false,
  }

  getTweet = (e) => {
    this.setState({btnActive: true});
    e.preventDefault()
    axios.get(`https://api.whatdoestrumpthink.com/api/v1/quotes/personalized?q=${this.state.name}`)
      .then(response => {
        this.props.callbackFromParent(response.data.message);
        this.setState({btnActive: false});
      })
      .catch(err => {
          console.log(err);
        });

  }

  handleNameInput = (e) => {
    this.setState({name: e.target.value})
  }

  render() {
   return (
     <div className="input">
       <form onSubmit={this.getTweet}>
         <input autoComplete="off" id="input"type="text" placeholder="Enter Name ..." onChange={this.handleNameInput} value={this.state.name}/>
         <button className={`button ${this.state.btnActive ? 'button--active' : ''}`}>GO!</button>
       </form>
     </div>
   )
  }
}

export default Input;
